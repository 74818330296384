import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from './locales/de/translation.json';

// the translations
const resources = {
    de: {
        translation: translationEN
    }
};

i18n
    .use(reactI18nextModule)
    .init({
        resources,
        lng: "de",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;