import React from 'react';
import i18next from 'i18next';
import {
    useLocation
} from "react-router-dom";
import DefaultLayout from './../layouts/Default';



export default function NoMatchPage() {
    let location = useLocation();

    return (
        <DefaultLayout
            content={
                <div className="empty-search">
                    <svg
                        height="300"
                        width="300"
                        fill="#BEBEBE">
                            <title>Leere Suche</title>
                        <use xlinkHref="/svg/images.svg#empty-search" />
                    </svg>
                    <p
                        className="empty-search__description">{ i18next.t('Nothing Found') } <code>{ location.pathname + location.search }</code></p>
                </div>
            }>
        </DefaultLayout>
    )
}