import React from 'react'
import Card from './Card'
import { connect } from 'react-redux';
import { 
    setOpenVideoModal,
    setVideoModalData,
    setOpenContentModal,
    setContentModalData
} from '../../../store/app/actions';
import { setMyCardsCollection } from '../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class CardContainer extends React.Component {
    render() {
        return (
            <Card {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showVideoModal: state.app.showVideoModal,
        myCardsCollection: state.share.myCardsCollection,
        showContentModal: state.app.showContentModal
    }
}
  
const mapDispatchToProps = {
    setOpenVideoModal,
    setVideoModalData,
    setOpenContentModal,
    setContentModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CardContainer)