import React from 'react';
import i18next from 'i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import CloseButton from 'react-bootstrap/CloseButton';
import CategoriesCheckboxList from './../../common/CategoriesCheckboxList/CategoriesCheckboxListContainer';
import ClearButton from '../../common/ClearButton/ClearButtonContainer';
import { MobileCategoryDropdownButton } from './../../../lib/elements/MobileCategoryDropdownButton';
import './FilterCategories.sass';
import { useState } from 'react';
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

export default class FilterCategories extends React.Component {
    
    DropdownPersist(props){
        const [open, setOpen] = useState(false);
        const onToggle = (isOpen, ev, metadata) => {
        if (metadata.source === "select") {
            setOpen(true);
            return;
            }
            setOpen(isOpen);
        };
        return <Dropdown show={open} onToggle={onToggle} {...props}></Dropdown>;
    }

    checkSwitch(){
        if(this.props.switchStatus === true){
            return true;
        } else{
            return false;
        }
    }
    
    render(){
        return (
            <div className="mobile-category-filter">
                <div className="mobile-category-filter__header">
                    <h2 className="mobile-category-filter__title">{ i18next.t('Filtering') }</h2>
                    <div className="mobile-category-filter__close">
                        <CloseButton
                        onClick={ () => {
                            this.props.setMobileNav(0)} }/>
                    </div>
                    <div className="mobile-category-filter__buttons">
                        <ClearButton className="mb-2" />
                        <button 
                            className="btn btn-primary mx-2 mb-2"
                            onClick={ () => {
                                this.props.setMobileNav(0, '/')} }>
                            { i18next.t('Apply') }
                        </button>
                        <div className="mobile-category-filter__switch">
                            <Form>
                                <Form.Check
                                type="switch"
                                id='control-switch'
                                defaultChecked={this.checkSwitch()}
                                onClick={() => {this.props.setSwitch(!this.props.switchStatus)}}
                                label={this.checkSwitch() ? 'alle Anzeigen' : 'nur DGUV Schriften'}
                                >
                                </Form.Check>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="mobile-category-filter__categories">
                    { this.props.groupesList.map((group, index) => {
                        if(group.categories.length !== 1) {
                            return (
                                <this.DropdownPersist
                                    className="mobile-category-filter__category dropdown btn-group"
                                    key={ index }
                                    focusFirstItemOnShow='keyboard'>
                                    <Dropdown.Toggle
                                        as={ MobileCategoryDropdownButton }>
                                        { group.title }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className="mobile-category-filter__category-title"
                                        role="menu">
                                    <CategoriesCheckboxList
                                        categories={ group.categories } />
                                    </Dropdown.Menu>
                                </this.DropdownPersist>
                            )
                        } else {
                            return false;
                        }
                    }) }
                </div>
            </div>
        )
    }
}

FilterCategories.defaultProps = {
    groupesList: []
}

FilterCategories.propTypes = {
    setMobileNav: PropTypes.func,
    groupesList: PropTypes.array
}