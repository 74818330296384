import React from 'react';
import i18next from 'i18next';
import CategoryLayout from './../layouts/CategoryContainer';
import shareLinkGenerator from '../lib/share/shareLinkGenerator';
import copyToClipboard from '../lib/copy/copyToClipboard';
import PropTypes from 'prop-types'


export default function CollectionPage(props) {

    function getCardsByShared() {
        return props.cardsList.filter(card => {
            return props.myCardsCollection.includes(card.id);
        });
    }

    return (
        <CategoryLayout
            titleMarkup={ 
                <>
                <h1 className="h1">{ i18next.t('Your collection') }</h1>
                <div className="collection-nav">
                    <input 
                        type="url"
                        disabled
                        className="collection-nav__link-area text-truncate"
                        value={ shareLinkGenerator(props.myCardsCollection).toString() } />
                    <button 
                        className="collection-nav__button collection-nav__button--copy btn btn-primary"
                        onClick={ () => copyToClipboard(shareLinkGenerator(props.myCardsCollection)) }>
                        { i18next.t('Copy to clipboard') }
                    </button>
                    <button 
                        className="collection-nav__button collection-nav__button--clear btn btn-danger"
                        onClick={ () => {
                            props.setMyCardsCollection([]);
                            window.localStorage.removeItem('myCardsCollection');
                        } }>
                        { i18next.t('Clear collection') }
                    </button>
                </div>
                </>
            }
            cards={ getCardsByShared() }>
        </CategoryLayout>
    )
}

CollectionPage.defaultProps = {
    myCardsCollection: [],
    cardsList: []
}

CollectionPage.propTypes = {
    myCardsCollection: PropTypes.array,
    setMyCardsCollection: PropTypes.func,
    cardsList: PropTypes.array
}