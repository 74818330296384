import React, { useState } from 'react';
import i18next from 'i18next';
import './FoldCard.sass';
import { addNotify, removeNotify } from '../Toast/Toast';
import PropTypes from 'prop-types';



export default function FoldCard(props) {
    const [flip, setFlip] = useState(null);

    function CheckCardType(props) {
        if (!props.type || props.type.id === 31 || props.type.id === 18) {
            return null;
        }
        return (
            <span className={props.classes}>{ props.type.title }</span>
        );
    }
    CheckCardType.propTypes = {
        type: PropTypes.object,
        classes: PropTypes.string
    }


    if(!props.heroCard){
    return (
        <div
        className={`flip-card ${flip === true ? 'flip-card--flipped' : ''} ${ props.className }`}
        title={ props.portal_title }>
            <div className="flip-card__image-area">  
                <div className="flip-card__flip-area">
                    <div
                    className="flip-card__flip-side flip-card__flip-side--front flip-card__image-area-link"
                    onClick={ () => setFlip(!flip) }
                    onKeyPress={() => setFlip(!flip)}
                    tabIndex={flip ? -1 : 0}>
                        { props.media_cover ? (
                            <img 
                            className="flip-card__image"
                            data-id={ props.id }
                            src={ props.media_cover }
                            alt={ props.portal_title }/>
                        )
                        :
                        (
                            <span>No image</span>
                        )}
                    </div>
                        <div 
                        className="flip-card__flip-side flip-card__flip-side--back"
                        onClick={ () => setFlip(!flip) }
                        onKeyPress={() => setFlip(!flip)}
                        tabIndex={flip ? 0 : -1}>  
                            <h4 className="flip-card__unfold">{ props.portal_title }</h4>
                        </div>
                    </div>
                    {props.myCardsCollection.includes(props.id) ?
                        <button 
                            className="flip-card__button flip-card__button--remove-from-favorites"
                            title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                            onClick={ () => {props.removeCard(); removeNotify()} }>
                                <span>
                                    <svg
                                        height="24"
                                        width="24"
                                        >
                                        <title>{i18next.t('Remove from')}</title>
                                        <use xlinkHref="svg/bootstrap-icons.svg#check" />
                                    </svg>
                                </span>
                        </button>
                        :
                        <button 
                            className="flip-card__button flip-card__button--add-to-favorites"
                            title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                            onClick={ () => {props.addCard(); addNotify()} }>
                                <span>
                                    <svg
                                        height="16"
                                        width="16">
                                            <title>{i18next.t('Add to')}</title>
                                        <use xlinkHref="svg/bootstrap-icons.svg#plus-lg" />
                                    </svg>
                                </span>
                        </button>
                    }
                    { props.is_recommendation === 1 &&
                        <span 
                            className="flip-card__icon flip-card__icon--featured"
                            title={ i18next.t('Featured') }>
                            <svg
                                height="20"
                                width="20">
                                    <title>{i18next.t('Featured')}</title>
                                <use
                                    xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                            </svg>
                        </span>
                    }
                    <CheckCardType type={props.type} classes={'flip-card__type flip-card__type--hide-flip'} />
                </div>
            <div
            className="flip-card__info"
            onClick={ () => setFlip(!flip)}>
                <span 
                className="flip-card__title flip-card__title--cutted">
                    { props.portal_title }
                </span>
                {flip === true ? 
                    <span>
                        <svg
                            height="16"
                            width="16">
                                <title>Öffnen</title>
                            <use xlinkHref="svg/bootstrap-icons.svg#chevron-up" />
                        </svg>
                    </span>
                    :
                    <span>
                        <svg
                            height="16"
                            width="16">
                                <title>Schließen</title>
                            <use xlinkHref="svg/bootstrap-icons.svg#chevron-down" />
                        </svg>
                    </span>
                }
            </div>
            <div className="external-container">
                <a  
                className="mt-auto external-link"
                href={ props.is_withdrawn === null || props.is_withdrawn === false ? props.link : 'undefined' }
                target={ props.is_withdrawn === null || props.is_withdrawn === false ? '_blank' : 'undefined' } rel="noreferrer">
                    { i18next.t('Show document') }
                 </a>
            </div>
        </div>
    )} else{
        return(
            <div
                className="heroscreen__card">
                { props.media_cover ? (
                    <img 
                        className="heroscreen__card-image"
                        data-id={ props.id }
                        src={ props.media_cover }
                        alt={ '' }/>
                    )
                    :
                    (
                    <span>No image</span>
                )}
            </div>
        )
    }
}

FoldCard.defaultProps = {
    myCardsCollection: []
}

FoldCard.propTypes = {
    type: PropTypes.object,
    classes: PropTypes.string,
    className: PropTypes.string,
    portal_title: PropTypes.string,
    media_cover: PropTypes.string ,
    id: PropTypes.number,
    myCardsCollection: PropTypes.array,
    removeCard: PropTypes.func,
    addCard: PropTypes.func,
    is_recommendation: PropTypes.number ,
    link: PropTypes.string,
    is_withdrawn: PropTypes.string
};