// import i18next from 'i18next';



export default function getFormats(cards) {
    let formats = [];
    formats = cards.filter(card => card.format != null).map(card => card.format);
    formats = Array.from(new Set(formats.map(a => a.id)))
        .map(id => {
            return formats.find(a => a.id === id);
        })
        .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
    // formats.push({
    //     id: null,
    //     name: i18next.t('No format')
    // });

    return formats
}