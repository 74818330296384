import React from 'react';
import CategoriesCheckboxList from './CategoriesCheckboxList';
import { connect } from 'react-redux';
import { 
    setCurrentCategories
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class CategoriesCheckboxListContainer extends React.Component {
    render() {
        return (
            <CategoriesCheckboxList {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesSelected: state.filter.categoriesSelected,
        cards: state.app.cardsList,
        groupesList: state.app.groupesList,
        switchStatus: state.filter.switch,
        filters: state.filter.dguvFilter,
        cardsCategorys : state.filter.cardsCategorys
    }
}
  
const mapDispatchToProps = {
    setCurrentCategories,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesCheckboxListContainer)