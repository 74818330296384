import { toast } from "react-toastify"

export const addNotify = () => 
        toast("Zum Regal hinzugefügt", 
        {
            position: 'bottom-right',
            progressStyle: {background: "#004994"},
            style: {color: 'black'},
            role: 'alert'
        })

export const removeNotify = () => 
        toast("Aus dem Regal entfernt", 
        {
            position: 'bottom-right',
            progressStyle: {background: "#004994"},
            style: {color: 'black'},
            role: 'alert'
        })