import React from 'react'
import App from './App'
import { connect } from 'react-redux';
import { 
    setShowMoreFilters, 
    setScreenSize,
    getAppData,
    setMainView,
    setListView
} from './store/app/actions'
import { setMyCardsCollection } from './store/share/actions';
import { setSearchCurrentArea } from './store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class AppContainer extends React.Component {
    render() {
        return (
            <App {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showMoreFilters: state.app.showMoreFilters,
        title: state.app.title,
        screenSize: state.app.screenSize,
        categoriesSelected: state.filter.categoriesSelected,
        groupesList: state.app.groupesList,
        // myCardsCollection: state.share.myCardsCollection,
        searchCurrentArea: state.filter.searchCurrentArea,
        searchString: state.filter.searchString,
        appDataLoaded: state.app.appDataLoaded,
    }
}
  
const mapDispatchToProps = {
    setShowMoreFilters,
    setScreenSize,
    getAppData,
    setMainView,
    setMyCardsCollection,
    setSearchCurrentArea,
    setListView
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)