import React from 'react';
import i18next from 'i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownLinkButton } from './../../../lib/elements/DropdownLinkButton';
import PropTypes from 'prop-types'


export default class ToggleCardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            togglesList: [
                {
                    value: 0,
                    title: i18next.t('Grid')
                },
                {
                    value: 1,
                    title: i18next.t('List')
                }
            ]
        };
    }

    handleToggleListView(value) {
        this.props.setListView(value);
        localStorage.setItem('listView', JSON.stringify(value));
    }

    render() {
        return (
            <div 
                className={`sort ${this.props.className}`}>
                <span 
                    className="sort__order-by">{ i18next.t('Show as') }:</span>
                <Dropdown
                    alignRight>
                    <Dropdown.Toggle 
                        as={DropdownLinkButton}
                        className={ this.props.buttonClassName }>
                        { this.state.togglesList.filter(item => item.value === parseInt(this.props.listView))[0].title + ' ' }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    { this.state.togglesList.filter(item => item.value !== parseInt(this.props.listView)).map(item => (
                        <Dropdown.Item 
                            eventKey={ item.value }
                            className="sort__dropdown-menu-item dropdown-item"
                            onClick={ () => this.handleToggleListView(item.value) }
                            key={ item.value }>{ item.title }</Dropdown.Item>
                    )) }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}

ToggleCardList.defaultProps = {
    listView: 0
}

ToggleCardList.propTypes = {
    listView: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    buttonClassName: PropTypes.string,
    setListView: PropTypes.func,
    className: PropTypes.string
}