import React from 'react'
import SharedPage from './SharedPage';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class SharedPageContainer extends React.Component {
    render() {
        return (
            <SharedPage {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.filter.searchString,
        cardsList: state.app.cardsList,
        filterOrder: state.app.filterOrder
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(SharedPageContainer)