export const FILTER_SET_ORDER = 'FILTER_SET_ORDER';
export const FILTER_SET_SEARCH = 'FILTER_SET_SEARCH';
export const FILTER_SEARCH_AREAS_AVAILABLE = 'FILTER_SEARCH_AREAS_AVAILABLE';
export const FILTER_SEARCH_AREA = 'FILTER_SEARCH_AREA';
export const FILTER_SET_CATEGORIES = 'FILTER_SET_CATEGORIES';
export const FILTER_CLEAR = 'FILTER_CLEAR';
export const FILTER_SEARCH_COUNT = 'FILTER_SEARCH_COUNT';
export const FILTER_SET_SWITCH = 'FILTER_SET_SWITCH';
export const FILTER_SET_CARDCATEGORIES = 'FILTER_SET_CARDCATEGORIES';




export const setOrder = (order) => ({
    type: FILTER_SET_ORDER,
    payload: order
})

export const setSearchString = (searchString) => ({
    type: FILTER_SET_SEARCH,
    payload: searchString
})

export const setCurrentCategories = (categorySelected) => ({
    type: FILTER_SET_CATEGORIES,
    payload: categorySelected
})

export const setSearchAreas = (areas) => ({
    type: FILTER_SEARCH_AREAS_AVAILABLE,
    payload: areas
})

export const setSearchCurrentArea = (area) => ({
    type: FILTER_SEARCH_AREA,
    payload: area
})

export const clearFilters = (groups) => ({
    type: FILTER_CLEAR,
    payload: groups
})

export const setSearchCount = (searchCount) => ({
    type: FILTER_SEARCH_COUNT,
    payload: searchCount
})

export const setSwitch = (input) => ({
    type: FILTER_SET_SWITCH,
    payload: input
})

export const setCardCategories = (categories) => ({
    type: FILTER_SET_CARDCATEGORIES,
    payload: categories
})