import React from 'react';
import ShelvesList from './ShelvesList';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class ShelvesListContainer extends React.Component {
    render() {
        return (
            <ShelvesList {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appDataLoaded: state.app.appDataLoaded,
        shelves: state.app.shelves,
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ShelvesListContainer)