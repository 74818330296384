import React from 'react';
//import Dropdown from 'react-bootstrap/Dropdown';
import CategoriesCheckboxList from './../../common/CategoriesCheckboxList/CategoriesCheckboxListContainer';
//import { CategoryDropdownButton } from './../../../lib/elements/CategoryDropdownButton';
import './FilterCategories.sass';
//import { useState } from 'react';
import PropTypes from 'prop-types'


export default class FilterCategories extends React.Component {
    selectedCounter(array) {
        let compare = [];
        let ids = array.map(item => item.id);
        for(let i in this.props.categoriesSelected) {
            if(ids.indexOf(this.props.categoriesSelected[i]) > -1){
                compare.push(this.props.categoriesSelected[i]);
            }
        }
        return compare.length;
    }

    render() {
        return (
            <div
                className={`filter-categories ${ this.props.className }`}
                role="group">
                { this.props.groupesList.map(group => {
                    if(group.categories.length !== 1) {
                        if( !this.props.expanded ) {
                            return (
                                <button 
                                key={ group.id } 
                                className="filter-categories__open-button" 
                                onClick={() => this.props.setShowMoreFilters(!this.props.showMoreFilters)}>
                                        { group.title }
                                        { this.selectedCounter(group.categories)  >= 1 &&
                                        <span className="filter-categories__category-counter">
                                            { this.selectedCounter(group.categories) }
                                        </span>
                                        }
                                </button>
                            )
                        } else { 
                            return (
                                <div 
                                    className="filter-categories__category"
                                    key={ group.id }>
                                    <span className="filter-categories__category-title">
                                        { group.title }
                                        { this.selectedCounter(group.categories)  >= 1 &&
                                        <span className="filter-categories__category-counter">
                                            { this.selectedCounter(group.categories) }
                                        </span>
                                    }
                                    </span>
                                    <CategoriesCheckboxList
                                        categories={ group.categories } />
                                </div>
                            )
                        }
                    } else {
                        return false;
                    }
                }
            )}
            </div>
        )
    }
}

FilterCategories.defaultProps = {
    groupesList: []
}

FilterCategories.propTypes = {
    categoriesSelected: PropTypes.array,
    className: PropTypes.string,
    groupesList: PropTypes.array,
    expanded: PropTypes.bool
}