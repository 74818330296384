import React from 'react'
import CollectionPage from './CollectionPage'
import { connect } from 'react-redux';
import { 
    setMyCardsCollection
} from '../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class CollectionPageContainer extends React.Component {
    render() {
        return (
            <CollectionPage {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cardsList: state.app.cardsList,
        myCardsCollection: state.share.myCardsCollection,
    }
}
  
const mapDispatchToProps = {
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CollectionPageContainer)