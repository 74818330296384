import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import Card from '../Card/CardContainer';
import CardsList from '../CardsList/CardsListContainer';
import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';
import orderBy from '../../../lib/array/orderBy';
import './ShelfBox.sass';
import PropTypes from 'prop-types'

export default function ShelfBox(props) {
    /**
     * Function to show cards list with header includes title and link. 
     * Link is optional.
     * 
     * @param {string} link — link (optional).
     * @param {string} title — title to show.
     * @param {array} cards — cards list.
     */

    function cardsToRender() {
        let cards = orderBy(props.cards, props.order);
        if(inResponsiveSizes(['xl'], props.screenSize)){
            return cards.slice(0, 6)
        } else if(inResponsiveSizes(['lg'], props.screenSize)){
            return cards.slice(0, 5)
        } else if(inResponsiveSizes(['md'], props.screenSize)){
            return cards.slice(0, 4)
        } else{
            return cards
        }
        
    }

    return (
        <div className="shelf-box">
            {/* Title */}
            <h3
                className="shelf-box__header-title">
                { props.title }
            </h3>

            {/* Description */}
            { props.content &&
                <p 
                    className="shelf-box__description"
                    dangerouslySetInnerHTML={{__html: props.content }}>
                </p>
            }
            
            {/* Link to shelf */}
            <NavLink
                className="shelf-box__show-more"
                to={ props.link }>
                { i18next.t('Show all') }
                <svg className="arrow" height="13" width="13">
                    <title>Weiter</title>
                    <use href="svg/bootstrap-icons.svg#chevron-right">
                    </use>
                </svg>
            </NavLink>

            {/* Cards list */}
            {/* { !inResponsiveSizes(['xs', 'sm'], props.screenSize) ? */}
            { parseInt(props.listView) === 0 ?
            <div 
                className={`shelf-box__list shelf-box__list--${ !inResponsiveSizes(['xs', 'sm'], props.screenSize) ? 'desktop' : 'mobile' }`}>
                { cardsToRender().map(card => (
                    <Card 
                        className="shelf-box__card"
                        key={ card.id }
                        card={ card }/>
                )) }
            </div>
            : 
                <CardsList 
                    className="shelf-box__list"
                    cards={ cardsToRender().slice(0, 6) } />
            }
        </div>
    )
}

ShelfBox.defaultProps = {
    cards: [],
    link: '/'
}

ShelfBox.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    listView: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    order: PropTypes.number,
    cards: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string
}