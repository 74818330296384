export default function checkResolution() {
    /**
     * Function that checks resolution and output one of three variants
     * that contains in config.
     */
    let size = window.innerWidth;
    if(size < 576) {
        return 'xs'
    } else if (size >= 576 && size < 768) {
        return 'sm'
    } else if (size >= 768 && size < 992) {
        return 'md'
    } else if (size >= 992 && size < 1200) {
        return 'lg'
    } else {
        return 'xl'
    }
}