import React from 'react';
import i18next from 'i18next';
// import BootstrapModal from 'react-bootstrap/Modal';
import { CSSTransition } from 'react-transition-group';
import './VideoModal.sass';
import $ from 'jquery'
import { addNotify, removeNotify } from '../Toast/Toast';
import PropTypes from 'prop-types'

export default class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            videoPlaybackConfirmed: !!localStorage.getItem('videoPlaybackConfirmed'),
            rememberConfirm: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.showVideoModal !== prevProps.showVideoModal) {
            if(this.props.showVideoModal === true) {
                document.body.style.overflow = 'hidden';
                document.addEventListener("keydown", this.keyListener);
            } else {
                document.body.style.overflow = '';
                document.removeEventListener("keydown", this.keyListener);
            }
        }
        if (this.props.showVideoModal && this.props.showVideoModal !== prevProps.showVideoModal) {
            this.setState({
                videoPlaybackConfirmed: !!localStorage.getItem('videoPlaybackConfirmed'),
                rememberConfirm: false
            });
        }
    }

    handleClose = () => {
        this.props.setOpenVideoModal(false);
    };

    handleShow = () => {
        this.props.setOpenVideoModal(true)
    };

    handleConfirm = () => {
        if (this.state.rememberConfirm) {
            console.log("remember set/localstorga");
            localStorage.setItem('videoPlaybackConfirmed', "yes");
        }
        console.log("set confirm")
        this.setState({
            videoPlaybackConfirmed: true
        });
    }

    handleRememberChange = () => {
        this.setState({
            rememberConfirm: !this.state.rememberConfirm
        });
    }

    onAddCardToCollection = (id) =>  {
        this.props.setMyCardsCollection([...this.props.myCardsCollection, id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...this.props.myCardsCollection, id]));
    }

    onRemoveCardFromCollection = (id) =>  {
        let filteredCards = this.props.myCardsCollection.filter(card => card !== id);
        this.props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    handleTabKey = () => {
        switch(document.activeElement.className){
            case "video-modal":
                $(".video-modal__close-button").trigger("focus");
                break;
            case "video-modal__close-button":
                $(".video-modal__frame").trigger("focus");
                break;
            case "btn btn-primary":
                $(".video-modal").trigger("focus");
                break;
            case "btn btn-danger":
                $(".video-modal").trigger("focus");
                break;
            case "video-card__image-area-link":
                $(".video-modal").trigger("focus");
                break;
            default:
                break;
        }


    };


    keyListener = (e) => {
        if (e.key === "Escape") {
            this.handleClose();
        }
        if (e.key === "Tab"){
            this.handleTabKey(e)
        }
    }

    render() {
        return (
            <CSSTransition
                in={ this.props.showVideoModal }
                timeout={ 400 }
                classNames="video-modal"
                unmountOnExit >
                <div
                    className="video-modal"
                    tabIndex={0}
                    onKeyPress={this.keyListener}>
                    <button
                        className="video-modal__close-button"
                        onClick={ () => this.handleClose() }
                        tabIndex={0}>&times;</button>
                    <div className="video-modal__frame" style={ {position: 'relative'} }>
                        {!this.state.videoPlaybackConfirmed ?
                            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: 'url(' + this.props.videoModalData.cover + ')', backgroundSize: 'cover'}}>
                                <div style={ {textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(5px)', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                                    <div>
                                        <p>Mit Aufruf des Videos erklären Sie sich einverstanden, dass Ihre Daten an einen externen Dienstleister übermittelt werden.<br/>Weitere Informationen finden Sie in der  <a href="/datenschutz#einbindung-drittanbieter">Datenschutzerklärung</a>.</p>
                                        <p><button type="button" onClick={ () => this.handleConfirm(this) }>Video abspielen</button></p>
                                        <p><label><input type="checkbox" checked={this.state.rememberConfirm} onChange={this.handleRememberChange} /> Nicht mehr nachfragen</label></p>
                                    </div>
                                </div>
                            </div>
                            :
                            <iframe
                                src={ this.props.videoModalData.link }
                                width="640"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                                title="video"
                                tabIndex={0}/>
                        }
                    </div>
                    <div className="video-modal__title">
                        <h1>
                            { this.props.videoModalData.portal_title }
                        </h1>
                        { this.props.videoModalData.is_recommendation === 1 &&
                            <span
                                className="flip-card__icon flip-card__icon--featured"
                                title={ i18next.t('Featured') }>
                            <svg
                                className ="video-modal__recomendation-icon"
                                height="16"
                                width="16">
                                    <title>{i18next.t('Featured')}</title>
                                <use
                                    xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                            </svg>
                                { i18next.t('Featured') }
                        </span>
                        }
                    </div>
                    <div className="video-modal__buttons">
                        { this.props.myCardsCollection.includes(this.props.videoModalData.id) ?
                            <button
                                className="btn btn-danger"
                                title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                                onClick={ () => {this.onRemoveCardFromCollection(this.props.videoModalData.id); removeNotify()} }
                                tabIndex={0}>
                                { i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                            </button> :
                            <button
                                className="btn btn-primary"
                                title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                                onClick={ () => {this.onAddCardToCollection(this.props.videoModalData.id); addNotify()} }
                                tabIndex={0}>
                                { i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                            </button>
                        }
                    </div>
                    <div
                        className="video-modal__description">
                        <div dangerouslySetInnerHTML={{__html: this.props.videoModalData.content }} />
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

Modal.defaultProps = {
    videoModalData: {},
    myCardsCollection: [],
}

Modal.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    setOpenVideoModal: PropTypes.func,
    setVideoModalData: PropTypes.func,
    videoModalData: PropTypes.object,
    myCardsCollection: PropTypes.array,
    showVideoModal: PropTypes.bool,
    setMyCardsCollection: PropTypes.func,
}