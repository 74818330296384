import React from 'react';
import i18next from 'i18next';
import './ContentCard.sass';
import { addNotify, removeNotify } from '../Toast/Toast';
import PropTypes from 'prop-types';

export default function ContentCard(props) {

    function onCallModalHandler() {
        /** 
         * Call modal window if card is content-card.
         */
        props.setOpenContentModal(true);
        props.setContentModalData({
            portal_title: props.portal_title,
            content: props.content,
            id: props.id
        });
    }


    return (
        
        <div
            className={`content-card ${props.className} `}
            title={ props.portal_title }>
            <div className="content-card__image-area">
                <span 
                    className="content-card__image-area-link"
                    onClick={ () => onCallModalHandler() }
                    onKeyPress={ () => onCallModalHandler() }
                    tabIndex={0}>
                    { props.media_cover ? (
                        <img 
                            className="content-card__image"
                            data-id={ props.id }
                            src={ props.media_cover }
                            alt={ props.portal_title }/>
                    ) : (
                        <span>No image</span>
                    )}
                </span>
                {
                    props.myCardsCollection.includes(props.id) ? 
                    <button 
                        className="content-card__button content-card__button--remove-from-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        onClick={ () => {props.removeCard(); removeNotify()} }>
                        <span>
                            <svg
                                height="24"
                                width="24">
                                    <title>{i18next.t('Remove from')}</title>
                                <use xlinkHref="svg/bootstrap-icons.svg#check" />
                            </svg>
                        </span>
                    </button> :
                    <button 
                        className="content-card__button content-card__button--add-to-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        onClick={ () => {props.addCard(); addNotify()} }>
                        <span>
                            <svg
                                height="16"
                                width="16">
                                    <title>{i18next.t('Add to')}</title>
                                <use xlinkHref="svg/bootstrap-icons.svg#plus-lg" />
                            </svg>
                        </span>
                    </button>
                }
                { props.is_recommendation === 1 && 
                <span 
                    className="content-card__icon content-card__icon--featured"
                    title={ i18next.t('Featured') }>
                    <svg
                        height="20"
                        width="20">
                            <title>{i18next.t('Featured')}</title>
                        <use
                            xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                    </svg>
                </span>
                }
                { props.type &&
                    <span
                        className="content-card__type">
                        { props.type.type }
                    </span>
                }
            </div>
            { !props.heroCard &&
                <div 
                    className="content-card__info">
                    <span 
                        className="content-card__title content-card__title--cutted"
                        onClick={ () => onCallModalHandler() }>
                        { props.portal_title }
                    </span>
                </div>
            }
        </div>
    )
}

ContentCard.defaultProps = {
    myCardsCollection: []
};

ContentCard.propTypes = {
    type: PropTypes.object,
    className: PropTypes.string,
    portal_title: PropTypes.string,
    media_cover: PropTypes.string ,
    id: PropTypes.number,
    myCardsCollection: PropTypes.array,
    removeCard: PropTypes.func,
    addCard: PropTypes.func,
    is_recommendation: PropTypes.number,
    setOpenContentModal: PropTypes.func,
    setContentModalData: PropTypes.func,
    content: PropTypes.string,
    heroCard: PropTypes.bool,
};