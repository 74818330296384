import React from 'react';
import { 
    useHistory,
    useLocation
} from "react-router-dom";
import i18next from 'i18next';
import './Search.sass'
import PropTypes from 'prop-types'


export default function Search(props) {
    let location = useLocation();
    let history = useHistory();

    function onSearchTypeHandle(e) {
        props.setSearchString(e.target.value);
        props.setSearchCount(1);
        props.setShowMoreFilters(false)
    }

    function handleSearch(e){
        if(e.key === 'Enter'&& props.searchString.length > 0 && location.pathname !== '/search'){
            history.push('/search')
        }
    }

    return (
        <div className="search">
            <input 
                className={`global-search ${props.className}`}
                type="search"
                autoFocus={ props.autoFocus }
                value={ props.searchString }
                onChange={ e => onSearchTypeHandle(e) }
                onKeyPress={e => handleSearch(e) }
                placeholder={ i18next.t('Type to search') }
                aria-label="Suche"/>
            <button className="search__button" onClick={props.searchString.length > 0 && location.pathname !== '/search' ? () => history.push('/search') : () => {} }>  
            <svg id="Gruppe_1213" data-name="Gruppe 1213" xmlns="http://www.w3.org/2000/svg" width="26.321" height="26.377" viewBox="0 0 26.321 26.377">
                <g id="Gruppe_1100" data-name="Gruppe 1100" transform="translate(0 0)">
                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="2.5">
                        <ellipse cx="10.428" cy="10.428" rx="10.428" ry="10.428" stroke="none"/>
                        <ellipse cx="10.428" cy="10.428" rx="9.178" ry="9.178" fill="none"/>
                    </g>
                </g>
                <line id="Linie_6" data-name="Linie 6" x2="9.104" y2="8.537" transform="translate(16.361 16.928)" fill="none" stroke="#fff" strokeWidth="2.5"/>
            </svg>
            </button>
        </div>
    )
}

Search.propTypes = {
    setSearchString: PropTypes.func,
    setSearchCount: PropTypes.func,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    searchString: PropTypes.string
}