import React from 'react';
import CategoryLoader from './../CategoryLoader/CategoryLoader';



export default function FilterPageLoader() {

    return (
        <div className="category-loader">
            { [1, 2, 3].map(item => (
                <CategoryLoader 
                    key={ item }/>
            ))}
        </div>
    )
}