import React from 'react';
import Sort from './Sort';
import { connect } from 'react-redux';
import { 
    setOrder
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class SortContainer extends React.Component {
    render() {
        return (
            <Sort {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {
    setOrder
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SortContainer)