import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './AppContainer';
import { Provider } from 'react-redux'
//import logger from 'redux-logger'
import { store, sagaMiddleware }  from './store';
import { watchLoadAppData } from './sagas/api';


sagaMiddleware.run(watchLoadAppData)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
