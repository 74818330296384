import React from 'react';
import i18next from 'i18next';
// import BootstrapModal from 'react-bootstrap/Modal';
import { CSSTransition } from 'react-transition-group';
import './ContentModal.sass';
import $ from 'jquery'
import { addNotify, removeNotify } from '../Toast/Toast';
import PropTypes from 'prop-types'


export default class Modal extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.showContentModal !== prevProps.showContentModal) {
            if(this.props.showContentModal === true) {
                document.body.style.overflow = 'hidden';
                document.addEventListener("keydown", this.keyListener);
            } else {
                document.body.style.overflow = '';
                document.removeEventListener("keydown", this.keyListener);
            }
        }
    }

    handleClose = () => {
        this.props.setOpenContentModal(false)
    };

    handleShow = () => {
        this.props.setOpenContentModal(true)
    };

    onAddCardToCollection = (id) =>  {
        this.props.setMyCardsCollection([...this.props.myCardsCollection, id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...this.props.myCardsCollection, id]));
    }

    onRemoveCardFromCollection = (id) =>  {
        let filteredCards = this.props.myCardsCollection.filter(card => card !== id);
        this.props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    handleTabKey = () => {
        if($(".container:last-child ul > li:last-child > a").is(":focus") || $(".container:last-child div > p:last-child > a").is(":focus") ){
            $(".content-modal").trigger("focus");
        }
        else{
            switch(document.activeElement.className){
                case "content-modal":
                    $(".content-modal__close-button").trigger("focus");
                    break;
                case "content-card__image-area-link":
                    $(".content-modal").trigger("focus");
                    break;
                case "container:last-child ul > li:last-child":
                    break;
                default:
                    break;
            }
        }
    };


    keyListener = (e) => {
        if (e.key === "Escape") {
            this.handleClose();
        }
        if (e.key === "Tab"){
            this.handleTabKey(e)
        }
    }

    render() {
            return (
            <CSSTransition
                in={ this.props.showContentModal }
                timeout={ 400 }
                classNames="content-modal"
                unmountOnExit >
                <div 
                    className="content-modal"
                    tabIndex={0}
                    onKeyPress={this.keyListener}>
                    <button 
                        className="content-modal__close-button"
                        onClick={ () => this.handleClose() }
                        onKeyPress={this.keyListener}
                        tabIndex={0}>&times;</button>

                    <div 
                        className="content-modal__buttons"
                        onKeyPress={this.keyListener}>
                        
                        { this.props.myCardsCollection.includes(this.props.contentModalData.id) ? 
                            <button 
                                className="btn btn-danger"
                                title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                                onClick={ () => {this.onRemoveCardFromCollection(this.props.contentModalData.id); removeNotify()}}
                                onKeyPress={this.keyListener}
                                tabIndex={0}>
                                { i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                            </button> :
                            <button 
                                className="btn btn-primary"
                                title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                                onClick={ () => {this.onAddCardToCollection(this.props.contentModalData.id); addNotify()} }
                                onKeyPress={this.keyListener}
                                tabIndex={0}>
                                { i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                            </button>
                        }
                    </div>
                    <div
                        className="content-modal__frame"
                        onKeyPress={this.keyListener}>
                        <div 
                            className="content-modal__content"
                            dangerouslySetInnerHTML={{__html: this.props.contentModalData.content }}
                            tabIndex={0} />
                    </div>
                    <div 
                        className="content-modal__title"
                        onKeyPress={this.keyListener}>
                        <h1>
                            { this.props.contentModalData.portal_title }
                        </h1>
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

Modal.defaultProps = {
    contentModalData: {},
    myCardsCollection: []
}

Modal.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    setOpenContentModal: PropTypes.func,
    setContentModalData: PropTypes.func,
    contentModalData: PropTypes.object,
    myCardsCollection: PropTypes.array,
    showContentModal: PropTypes.bool,
    setMyCardsCollection: PropTypes.func
}