import React from 'react';
import { NavLink } from 'react-router-dom';
import './Logo.sass';
import PropTypes from 'prop-types'


export default function Logo(props) {
    return (
        <NavLink 
            className={`logo ${props.class}`}
            to="/"
            onClick={ () => props.clearFilters(props.groupesList) }>
            <span className="logo__brand">
                <span>{ props.brandName }</span>
            </span>
        </NavLink>
    )
}

Logo.propTypes = {
    class: PropTypes.string,
    clearFilters: PropTypes.func,
    groupesList: PropTypes.array,
    brandName: PropTypes.string
}