import React from 'react'
import CardsCarousel from './CardsCarousel'
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class CardsCarouselContainer extends React.Component {
    render() {
        return (
            <CardsCarousel {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(CardsCarouselContainer)