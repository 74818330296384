import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { useEffect } from "react";
import {
    useLocation
} from "react-router-dom";
import CategoryLayout from './../layouts/CategoryContainer';
import FilterLayout from './../layouts/FilterContainer';
import orderBy from './../lib/array/orderBy';
import filterCardsByCategories from './../lib/array/filterCardsByCategories';
import filterShelfBySlug from './../lib/array/filterShelfBySlug';


function ScrollToTop() {
    const { pathname, search } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);
  
    return null;
}

export default function FilterPage(props) {
    let query = new URLSearchParams(useLocation().search);
    let formatId = parseInt(query.get("format"));
    let shelf = query.get("shelf");

    function formatData() {
        let data = {...props.formats.filter(format => {
            return format.id === parseInt(formatId);
        })[0]}

        let cards = props.cardsList;
        cards = orderBy(cards, props.filterOrder);
        cards = filterCardsByCategories(cards, props.categoriesSelected, props.groupesList);
        cards = cards.filter(card => card.format_id === formatId);

        data.repos = cards;
        return data;
    }

    function shelfData() {
        return filterShelfBySlug(props.shelves, shelf);
    }

    if(formatId) {
        formatData()
        // Category view.
        return (
            <>
            <ScrollToTop />
            <CategoryLayout
                backlinkMarkup={ 
                    <NavLink
                        className="category__backlink home-backlink"
                        to={`/`}>
                        { i18next.t('Library') }
                    </NavLink>
                 }
                title={ formatData().name }
                cards={ formatData().repos } />
            </>
        )
    } else if(props.shelvesDataLoaded && shelf){
        shelfData()
        // Shelf view.
        return (
            <>
            <ScrollToTop />
            <CategoryLayout
                title={ shelfData().name }
                content={ shelfData().content }
                cards={ shelfData().repos } />
            </>
        )
    } else {
        return (
            <>
            <ScrollToTop />
            <FilterLayout />
            </>
        )

    }
}