import { combineReducers } from 'redux'
import { appReducer } from './app/reducers'
import { filterReducer } from './filter/reducers'
import { shareReducer } from './share/reducers';



export default combineReducers({
    app: appReducer,
    filter: filterReducer,
    share: shareReducer
});