import React from 'react';
import PropTypes from 'prop-types'


export const RegalDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className="my-link"
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }} >
        <span
            className="my-link__icon">
            <svg
                height="22"
                width="22">
                    <title>Regal</title>
                <use
                    xlinkHref="svg/bootstrap-icons.svg#archive-fill" />
            </svg>
        </span>
        {children}
        <svg
            height="13"
            width="13">
                <title>Öffnen</title>
            <use
                xlinkHref="svg/bootstrap-icons.svg#chevron-down" />
        </svg>
    </button>
));

RegalDropdownButton.displayName = 'RegalDropdownButton';

RegalDropdownButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ])

}