import React from 'react';
import VideoCard from './VideoCard';
//import FlipCard from './FlipCard';
import FoldCard from './FoldCard';
import ContentCard from './ContentCard'




export default function Card(props) {
    /**
     * Class Card gets few props like card and draws a card. Simple card.
     * 
     * @param {object} cards — object contains all card params like title.
     */


    function onAddCardToCollection() {
        props.setMyCardsCollection([...props.myCardsCollection, props.card.id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...props.myCardsCollection, props.card.id]));
    }

    function onRemoveCardFromCollection() {
        let filteredCards = props.myCardsCollection.filter(card => card !== props.card.id);
        props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    if(props.card.type && props.card.type.type === 'video')
    {
        return (
            <VideoCard
                { ...props.card }
                className={ props.className }
                myCardsCollection={ props.myCardsCollection }
                showVideoModal={ props.showVideoModal }
                setOpenVideoModal={ props.setOpenVideoModal }
                setVideoModalData={ props.setVideoModalData }
                setMyCardsCollection={ props.setMyCardsCollection }
                addCard={ onAddCardToCollection }
                removeCard={ onRemoveCardFromCollection }
                heroCard={ props.heroCard }/>
        )
    } else if(props.card.is_popup === 1){
        return (
            <ContentCard
                { ...props.card }
                className={ props.className }
                showContentModal={ props.showContentModal }
                setOpenContentModal={ props.setOpenContentModal }
                setContentModalData={ props.setContentModalData }
                myCardsCollection={ props.myCardsCollection }
                setMyCardsCollection={ props.setMyCardsCollection }
                addCard={ onAddCardToCollection }
                removeCard={ onRemoveCardFromCollection }/>
        )
    } else {
        return (
            <FoldCard
                { ...props.card }
                className={ props.className }
                myCardsCollection={ props.myCardsCollection }
                setMyCardsCollection={ props.setMyCardsCollection }
                addCard={ onAddCardToCollection }
                removeCard={ onRemoveCardFromCollection }
                heroCard={ props.heroCard } />
        )
    }
}

Card.defaultProps = {
    card: {},
    myCardsCollection: []
}