import React from 'react';
import Filter from './FilterCategories';
import { connect } from 'react-redux';
import { 
    setMobileNav,
} from './../../../store/app/actions';
import { 
    setCurrentCategories, setSwitch
} from '../../../store/filter/actions';


/**
 * Middleware to leave stupid component and clever.
 */
class FilterContainer extends React.Component {
    render() {
        return (
            <Filter {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesSelected: state.filter.categoriesSelected,
        groupesList: state.app.groupesList,
        switchStatus: state.filter.switch
    }
}
  
const mapDispatchToProps = {
    setMobileNav,
    setCurrentCategories,
    setSwitch
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer)