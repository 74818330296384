import React from 'react';
import { useEffect } from "react";
import {
    useLocation
} from "react-router-dom";
import CategoryLayout from './../layouts/CategoryContainer';
import Search from './../components/common/Search/SearchContainer';
import ClearButton from './../components/common/ClearButton/ClearButtonContainer';
import filterCardsBySearch from './../lib/array/filterCardsBySearch';
import orderBy from './../lib/array/orderBy';
import PropTypes from 'prop-types'
import inResponsiveSizes from '../lib/ui/inResponsiveSizes';


function ScrollToTop() {
    const { pathname, search } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);
  
    return null;
}

export default function SearchPage(props) {
    function arrayToRender() {
        let cards = props.cardsList;
        cards = filterCardsBySearch(cards, props.searchString);
        cards = orderBy(cards, props.filterOrder);
        return cards;
    }

    return (
        <>
        <ScrollToTop />
        <CategoryLayout
            titleMarkup={
                inResponsiveSizes(['xs','sm'], props.screenSize) ?
                <div className="search-row">
                    <Search 
                        className="search-row__input"
                        autoFocus={ true } />
                    <ClearButton
                        className="search-row__button" />
                </div>
                :
                <></>
            }
            cards={ arrayToRender() } />
        </>
    )
}

SearchPage.defaultProps = {
    cardsList: []
}

SearchPage.propTypes = {
    cardsList: PropTypes.array,
    searchString: PropTypes.string,
    filterOrder: PropTypes.number
}