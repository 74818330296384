import React from 'react';
import ShelfBox from './../ShelfBox/ShelfBoxContainer';
import orderBy from '../../../lib/array/orderBy';
import './ShelvesList.sass';
import PropTypes from 'prop-types'


export default class ShelvesList extends React.Component {

    render() {
        return (
            <div className="container shelves-list">
                { this.props.shelves.map((shelf, id) => (
                    <ShelfBox
                        className="shelves-list__item"
                        title={ shelf.name }
                        link={ `?shelf=${ shelf.slug }`}
                        cards={ orderBy(shelf.repos, this.props.order) }
                        content={ shelf.content }
                        key={ id } />
                )) }
            </div>
        )
    }
}

ShelvesList.defaultProps = {
    shelves: []
}

ShelvesList.propTypes = {
    shelves: PropTypes.array,
    order: PropTypes.number
}