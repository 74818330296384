import React from 'react';
import { useLocation } from 'react-router-dom';
import CategoryLayout from './../layouts/CategoryContainer';
import i18next from "i18next";
import PropTypes from 'prop-types'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SharedPage(props) {
    let query = useQuery();
    let sharedCards = query.getAll('card').map(card => parseInt(card));

    function getCardsByShared() {
        return props.cardsList.filter(card => {
            return sharedCards.includes(card.id);
        });
    }

    return (
        <CategoryLayout
            title={ i18next.t('Shared collection' ) }
            cards={ getCardsByShared() } />
    )
}

SharedPage.defaultProps = {
    cardsList: []
}

SharedPage.propTypes = {
    cardsList: PropTypes.array
}