import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import FilterHeader from '../components/desktop/FilterHeader/FilterHeaderContainer';
import Header from './../components/common/Header/HeaderContainer';
import Footer from './../components/common/Footer/Footer';
import MobileNav from './../components/mobile/MobileNav/MobileNavContainer';
import Sort from './../components/desktop/Sort/SortContainer';
import ToggleCardList from './../components/desktop/ToggleCardList/ToggleCardListContainer';
import FormatBox from './../components/common/FormatBox/FormatBoxContainer';
import filterCardsByCategories from './../lib/array/filterCardsByCategories';
import orderBy from './../lib/array/orderBy';
import inResponsiveSizes from './../lib/ui/inResponsiveSizes';
import FilterPageLoader from './../components/common/FilterPageLoader/FilterPageLoader';
import './Filter.sass';
import PropTypes from 'prop-types'
import ShelvesList from './../components/common/ShelvesList/ShelvesListContainer';
import Shepherd from '../components/common/Shepherd/Shepherd';
import { 
    useLocation
} from "react-router-dom";

export default function FilterLayout(props) {

    let location = useLocation();

    function arrayToRender() {
        let cards = props.cardsList;
        if(props.switchStatus === true){
            cards = filterForDGUV(cards);
        }
        cards = orderBy(cards, props.filterOrder);
        cards = filterCardsByCategories(cards, props.categoriesSelected, props.groupesList);
        return cards;
    }

    function filterForDGUV(cards){
        let result
        result = cards.filter(card => 
            props.filters.includes(card.type.type) 
        )
        return result
    }

    function checkIfNothingFound() {
        return arrayToRender().length !== 0 ? true : false;
    }

    function formatsList() {
        return props.formats.map(item => {
            item.cardsList = arrayToRender().filter(card => card.format_id === item.id);
            return item;
        })
    }

    return (
        <>
            <Header />
            { !inResponsiveSizes(['xs', 'sm'], props.screenSize) ?
                <FilterHeader />
            :
                <div className="head">
                    <h1 className="headline">Mediathek</h1>
                    {inResponsiveSizes(['xs'], props.screenSize) ?
                        <Shepherd type="mobile" />
                    :
                        <></>
                    }
                </div>
            }
            <div className="page__content">
                { !props.allCardsLoaded ? (
                    <FilterPageLoader />
                ) : (
                    <section className="filter">
                        {/* Backlink */}
                        {location.pathname !== '/' ?
                            <NavLink
                                className="filter__backlink home-backlink"
                                to={`/`}>
                                { i18next.t('Home') }
                            </NavLink>
                        :
                            <></>
                        }

                        {/* Title */}
                        {location.pathname !== '/' ?
                            <h1 className="filter__title">{ i18next.t('Library') }</h1>
                        :
                            <></>
                        }

                        {/* Desktop view togglers */}
                        { !inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                            <div className="filter__view-funcs">
                                <ToggleCardList />
                                <Sort />
                            </div> 
                        }
                        
                        {props.categoriesSelected.length === 0 && props.switchStatus === false ? 
                            <ShelvesList className="filter__shelves" />
                        :
                            <></>
                        }

                        { checkIfNothingFound() ? 
                            <div className="filter__formats-list">
                                { formatsList().map((item, index) => 
                                    item.cardsList.length !== 0 ? (
                                        <FormatBox
                                            title={ item.name }
                                            key={ index }
                                            link={ `?format=${ item.id }` }
                                            cards={ item.cardsList }/>
                                    ) : false
                                ) }
                            </div>
                            : (
                                <div className="filter__formats-list filter__formats-list--empty">
                                    <svg
                                        height="300"
                                        width="300"
                                        fill="#BEBEBE">
                                            <title>Leere Suche</title>
                                        <use xlinkHref="/svg/images.svg#empty-search" />
                                    </svg>
                                    <h1>{ i18next.t('Nothing Found') }</h1>
                                </div>
                            ) 
                        }
                    </section>
                )}
            </div>
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <MobileNav />
            }
            <Footer />
        </>
    )
}

FilterLayout.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    cardsList: PropTypes.array,
    filterOrder: PropTypes.number,
    categoriesSelected: PropTypes.array,
    groupesList: PropTypes.array,
    formats: PropTypes.array,
    allCardsLoaded: PropTypes.bool

}