import React from 'react';
import ToggleCardList from './ToggleCardList';
import { connect } from 'react-redux';
import { 
    setListView
} from '../../../store/app/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class ToggleCardListContainer extends React.Component {
    render() {
        return (
            <ToggleCardList {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.app.listView
    }
}
  
const mapDispatchToProps = {
    setListView
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ToggleCardListContainer)