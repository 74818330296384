export default function filterCardsByCategories(cards, categories, groups) {
    return cards.filter(card => {
        let groupsArray = groups.map(group => {
            let groupCategories = Array.from(new Set(group.categories.map(a => a.id)));
            return groupCategories.filter(id => {
                return categories.includes(id);
            })
        });
        return groupsArray.every(group => {
            let check = true;
            for(let i = 0; i < group.length; i++) {
                check = card.categories.some(category => {
                    return category.id === group[i];
                });
                if(check === true) {
                    return check;
                }
            }
            return check;
        });
    })
}