import React from 'react';
import Settings from './Settings';
import { connect } from 'react-redux';
import { 
    setOrder,
} from '../../../store/filter/actions';
import { 
    setMobileNav,
    setListView
} from '../../../store/app/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class SettingsContainer extends React.Component {
    render() {
        return (
            <Settings {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.filter.order,
        listView: state.app.listView
    }
}
  
const mapDispatchToProps = {
    setOrder,
    setListView,
    setMobileNav
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)