import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import Header from './../components/common/Header/HeaderContainer';
import FilterHeader from '../components/desktop/FilterHeader/FilterHeaderContainer';
import Footer from './../components/common/Footer/Footer';
import MobileNav from './../components/mobile/MobileNav/MobileNavContainer';
import Sort from './../components/desktop/Sort/SortContainer';
import ToggleCardList from './../components/desktop/ToggleCardList/ToggleCardListContainer';
import CardsList from './../components/common/CardsList/CardsListContainer';
import orderBy from '../lib/array/orderBy';
import noDuplicatesArray from './../lib/array/noDuplicatesArray';
import inResponsiveSizes from './../lib/ui/inResponsiveSizes';
import CategoryLoader from './../components/common/CategoryLoader/CategoryLoader';
import './Category.sass';
import { useState,useRef } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types'



export default function CategoryLayout(props) {
    function arrayToRender() {
        let cards = props.cards;
        if(props.switchStatus === true){
            cards = filterForDGUV(cards);
        }
        cards = orderBy(cards, props.order);
        cards = noDuplicatesArray(cards);
        return cards;
    }

    function filterForDGUV(cards){
        let result
        result = cards.filter(card => 
            props.filters.includes(card.type.type)
        )
        return result
    }


    function hasSearchInput() {
        let string = props.searchString;
        if(string.length !== 0){
            return true
        }else{ 
            return false
        }
    }


    function partArrayToRender() {
        let cards = props.cards;
        if(props.switchStatus === true){
            cards = filterForDGUV(cards);
        }
        let count = props.searchCount
        cards = orderBy(cards, props.order);
        cards = noDuplicatesArray(cards);
        if(inResponsiveSizes(['xs','sm'], props.screenSize)){
            return cards.splice(0, count * 10)
        }else {
            return cards.splice(0, count * 30)
        }
    }

    function handleSearchCountIncrese(){
        if(isVisible){
            setIsVisible(false)
            let count = props.searchCount
            count++;
            props.setSearchCount(count)
        }
    }

    const containerRef = useRef(null)
    const [ isVisible, setIsVisible] = useState(false)

    const callbackFunction = (entries) => {
        const [ entry ] = entries
        setIsVisible(entry.isIntersecting)
    }
    const options ={
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        const cntRef = containerRef.current
        if(containerRef.current){
            observer.observe(containerRef.current)
        }
        return () =>{
            if(cntRef){
                observer.unobserve(cntRef)
            }
        }
    })

    return (
        <>
            <Header />
            { !inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <FilterHeader />
            }
            { !props.allCardsLoaded ? (
                <CategoryLoader />
            ) : (
                <div className="page__content">
                    <section className="category">
                        {/* Backlink */}
                        { props.backlinkMarkup ? 
                            <>
                                { props.backlinkMarkup }
                            </>
                        :
                                <NavLink
                                    className="category__backlink home-backlink"
                                    to={`/`}>
                                    { i18next.t('Home') }
                                </NavLink>
                        }

                        {/* Title */}
                        { props.titleMarkup ? 
                            <div className="category__title">
                                { props.titleMarkup }
                            </div>
                        :
                           <h1 className="category__title">{ props.title }</h1>
                        }

                        {/* Description */}
                        { props.content &&
                            <p 
                                className="category__description"
                                dangerouslySetInnerHTML={{__html: props.content }}></p>
                        }

                        {/* Cards length counter */}
                       { !window.location.href.includes('/search') || hasSearchInput() ? <div className="category__counter">{  arrayToRender().length } { i18next.t('Entries') }</div> : <div></div>}
                        
                        {/* Desktop view togglers */}
                        { !inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                            <div className="category__view-funcs">
                                <ToggleCardList />
                                <Sort />
                            </div> 
                        }

                        {/* Cards list */}
                        {(!window.location.href.includes('/search') || hasSearchInput()) && arrayToRender().length !== 0 ?
                        <>
                            <CardsList
                                className="category__cards-list"
                                cards={ arrayToRender().length <= 10 ? arrayToRender() : partArrayToRender() }/>
                            {arrayToRender().length > 10 && (arrayToRender().length !== partArrayToRender().length) ? <div ref={containerRef} className="category__extension">{isVisible ? handleSearchCountIncrese() : ''}</div> : <></>}
                        </>
                        :
                            <div className="category__cards-list category__cards-list--empty">
                                <svg
                                    height="300"
                                    width="300"
                                    fill="#BEBEBE">
                                        <title>Leere Suche</title>
                                    <use xlinkHref="/svg/images.svg#empty-search" />
                                </svg>
                                <h1>{ i18next.t('Nothing Found') }</h1>
                            </div>
                        }
                    </section>
                </div>
            )}
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <MobileNav />
            }
            <Footer />
        </>
    )
}

CategoryLayout.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    cards: PropTypes.array,
    order: PropTypes.number,
    searchString: PropTypes.string,
    searchCount: PropTypes.number,
    setSearchCount: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    allCardsLoaded: PropTypes.bool,
    backlinkMarkup: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    titleMarkup: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    title: PropTypes.string,
    content: PropTypes.string
}