import React from 'react';
import VideoModal from './VideoModal';
import { connect } from 'react-redux';
import { 
    setOpenVideoModal,
    setVideoModalData
} from '../../../store/app/actions';
import { setMyCardsCollection } from './../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class VideoModalContainer extends React.Component {
    render() {
        return (
            <VideoModal {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showVideoModal: state.app.showVideoModal,
        videoModalData: state.app.videoModalData,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setOpenVideoModal,
    setVideoModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(VideoModalContainer)