import React from 'react';
import i18next from 'i18next';
import {NavLink} from 'react-router-dom';
import DefaultLayout from './../layouts/Default';




export default function SiteMapPage() {

    return (
        <DefaultLayout
            content={
                <div className="page__content container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">

                            <h1 className="mb-4">Sitemap</h1>
                            <div className="sitemap__container">
                                <nav className="sitemap__links">
                                    <ul className="nav flex-column justify-content-center">
                                        <li 
                                            className="nav-item">
                                            <NavLink 
                                                className="nav-link"
                                                to="/">{ i18next.t('Home') }</NavLink>
                                        </li>
                                        <li
                                            className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                to="/collection">{ i18next.t('Your collection') }</NavLink>
                                        </li>
                                        <li
                                            className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                to="/search">{ i18next.t('Search') }</NavLink>
                                        </li>
                                        <li 
                                            className="nav-item">
                                            <NavLink 
                                                className="nav-link"
                                                to="/impressum">{ i18next.t('Link imprint') }</NavLink>
                                        </li>
                                        <li 
                                            className="nav-item">
                                            <NavLink 
                                                className="nav-link"
                                                to="/datenschutz">{ i18next.t('Link disclaimer') }</NavLink>
                                        </li>
                                        <li
                                            className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                to="/barrierefreiheit">{ i18next.t('Accessibility') }</NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            }>
        </DefaultLayout>
    )
}