import React from 'react';
import Search from './../../common/Search/SearchContainer';
import ClearButton from '../../common/ClearButton/ClearButtonContainer';
import FilterCategories from '../FilterCategories/FilterCategoriesContainer';
import './FilterHeader.sass';
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'


export default function FilterHeader(props) {

    const key = "https://repos.rms2cdn.de/files"

    return (
        <div className='filters' style={props.hero ? {backgroundImage: `url(${key}${props.hero.cover})`} : {} }>      
            <h1 className='headline'>Mediathek</h1>
            <div 
                className={`page__filter-row filter-header ${props.showMoreFilters ? 'filter-header--opened' : ''}`}>
                <div className='filter-header__sticky'>
                    <Search
                        className="filter-header__search" />
                    <div className="filter-header__switch">
                        <Form>
                            <Form.Check
                            type="switch"
                            id='control-switch'
                            checked={props.switchStatus}
                            onChange={() => {props.setSwitch(!props.switchStatus)}}
                            label={props.switchStatus ? 'alle Anzeigen' : 'nur DGUV Schriften'}
                            >
                            </Form.Check>
                        </Form>
                    </div>
                    <button 
                        className={`filter-header__toggle btn ${props.showMoreFilters ? 'btn-primary' : 'btn-light'}`}
                        onClick={ () => {props.setShowMoreFilters(!props.showMoreFilters)}}
                        aria-label="Öffnen">
                        <svg
                            height="20"
                            width="20"
                            alt="Öffnen">
                                <title>Öffnen</title>
                            <use
                                xlinkHref="svg/bootstrap-icons.svg#funnel-fill" />
                        </svg>
                    </button>
                    <ClearButton 
                        className="filter-header__clear" />
                </div>
                    <FilterCategories
                        className="filter-header__categories"
                        expanded={ props.showMoreFilters } />
            </div>
            {props.showMoreFilters ? 
                <button className="filters__all-close" onClick={() => props.setShowMoreFilters(!props.showMoreFilters)}>Schließen</button>
            :
                <></>
            }
        </div>
    )
}

FilterHeader.propTypes = {
    showMoreFilters: PropTypes.bool,
    setShowMoreFilters: PropTypes.func
}