import React from 'react';
import Filter from './FilterCategories';
import { connect } from 'react-redux';
import { 
    setShowMoreFilters
} from '../../../store/app/actions';


/**
 * Middleware to leave stupid component and clever.
 */
class FilterContainer extends React.Component {
    render() {
        return (
            <Filter {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesSelected: state.filter.categoriesSelected,
        groupesList: state.app.groupesList,
        showMoreFilters: state.app.showMoreFilters,
    }
}
  
const mapDispatchToProps = {
    setShowMoreFilters,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer)