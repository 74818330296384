import React from 'react';
import MobileNav from './MobileNav';
import { connect } from 'react-redux';
import { 
    setMobileNav,
} from './../../../store/app/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class MobileNavContainer extends React.Component {
    render() {
        return (
            <MobileNav {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mobileNav: state.app.mobileNav,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setMobileNav
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MobileNavContainer)