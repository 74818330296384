import React from 'react';
import MyCollectionDropdown from './MyCollectionDropdown';
import { connect } from 'react-redux';
import { 
    setMyCardsCollection 
} from '../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class MyCollectionDropdownContainer extends React.Component {
    render() {
        return (
            <MyCollectionDropdown {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setMyCardsCollection 
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MyCollectionDropdownContainer)