import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import createSagaMiddelware from 'redux-saga';


export const sagaMiddleware = createSagaMiddelware();


export const store = createStore(
    rootReducer,
    applyMiddleware(
        //logger,
        sagaMiddleware
    )
)