import $ from 'jquery'

export const stepsDesktop = [
  {
      id: 'intro',
      buttons: [
        { 
          action (){
            return this.complete();
          },
          classes: 'btn-primary',
          text: '&times; ABBRECHEN',
        },
        {
          action(){
            return this.next();  
          },  
          classes: 'btn-primary',
          text: 'TOUR STARTEN',
        },
      ],
      classes: 'shepherd-box',
      highlightClass: 'highlight',
      scrollTo: false,
      arrow: false,
      title: 'Willkommen in der Mediathek!',
      text: ['Hier bekommen Sie eine kleine Einführung in die Benutzung der Mediathek.'],
    },
    {
        id: 'step01',
        buttons: [
          {
            action(){
              return this.next();  
            },  
            classes: 'btn-primary',
            text: 'FORTFAHREN',
          },
          
        ],
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.flip-card__button', on: 'top'},
        scrollTo: {behavior: 'smooth', block: 'center'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Element hinzufügen',
        text: ['Klicken Sie auf das Symbol (+),um ein Element dem Regal hinzuzufügen. Oder auf (&#10003;) um dieses wieder zu entfernen.'],
        arrow: true,
      },
      {
        id: 'step02',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.header__user-collection', on: 'bottom'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Mein Regal',
        canClickTarget: false,
        text: ['Hier finden Sie das Regal mit Ihren gespeicherten Dokumenten.'],
        buttons: [
          {
            action(){
              return this.next();  
            },  
            classes: 'btn-primary',
            text: 'FORTFAHREN',
          },
        ],
      },
      {
        id: 'step03',
        beforeShowPromise: function () {
          $('.my-link').trigger("click");
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 100);
          });
        },
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: 'div.dropdown-menu.show', on: 'right'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Über "Mein Regal" können Sie Ihr Regal öffnen. Mit "Link kopieren" speichern Sie den Link zu Ihrem aktuellen Regal und "Alles Entfernen" löscht alle hizugefügten Elemente aus Ihrem Regal'],
        buttons: [
          {
            action(){
              return this.complete();  
            },  
            classes: 'btn-primary',
            text: 'BEENDEN',
          },
        ],
        when: {
          hide: function() {
            $('.my-link').trigger("click");
          }
        }
      },
  ];
