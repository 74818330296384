import React from 'react';
import i18next from 'i18next';
import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';
import PropTypes from 'prop-types'

export default function ClearButton(props) {
    return (
        <button 
            className={`btn btn-danger ${props.className}`}
            onClick={ () => {
                props.clearFilters(props.groupesList);
                props.setMobileNav(0);
                props.setSwitch(false);
            } }>
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) ? i18next.t('Clear') : i18next.t('Clear filters') }
        </button>
    )
}

ClearButton.propTypes = {
    className: PropTypes.string,
    clearFilters: PropTypes.func,
    groupesList: PropTypes.array,
    setMobileNav: PropTypes.func,
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
}