import React from 'react';
import PropTypes from 'prop-types'

export const MobileCategoryDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
    
    <button
        ref={ref}
        className="mobile-category-dropdown-button"
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }} >
        <span className="mobile-category-dropdown-button__children">
            {children}
        </span>
        <span
            className="mobile-category-dropdown-button__icon">
            <svg
                height="20"
                width="20">
                    <title>Öffnen</title>
                <use
                    xlinkHref="svg/bootstrap-icons.svg#chevron-down" />
            </svg>
        </span>
    </button>
));

MobileCategoryDropdownButton.displayName = 'MobileCategoryDropdownButton';

MobileCategoryDropdownButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ])

}