import React from 'react';
import Search from './Search';
import { connect } from 'react-redux';
import { 
    setSearchString,
    setSearchCount
} from '../../../store/filter/actions';
import { 
    setShowMoreFilters
} from '../../../store/app/actions';


/**
 * Middleware to leave stupid component and clever.
 */
class SearchContainer extends React.Component {
    render() {
        return (
            <Search {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.filter.searchString,
        searchCount: state.filter.searchCount,
    }
}
  
const mapDispatchToProps = {
    setSearchCount,
    setSearchString,
    setShowMoreFilters,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)