import React from 'react';
import Card from '../Card/CardContainer';
import RowCard from './../RowCard/RowCardContainer';
// import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';
import './CardsList.sass';
import PropTypes from 'prop-types'



export default function CardsList(props) {
    return (
        <div 
            className={`cards-list ${parseInt(props.listView) === 0 ? 'cards-list--grid' : 'cards-list--rows' } ${props.className}`}>
            { props.cards.map(card => {
                return parseInt(props.listView) === 0 ? (
                    <Card 
                        className="cards-list__item"
                        key={ card.id }
                        card={ card }/>
                ) : (
                    <RowCard 
                        className="cards-list__item"
                        key={ card.id }
                        card={ card }/>
                )}
            ) }
        </div>
    )
}

CardsList.defaultProps = {
    cards: []
}

CardsList.propTypes = {
    className: PropTypes.string,
    listView: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    cards: PropTypes.array
}