import React from 'react'
import CardsList from './CardsList'
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class CardsListContainer extends React.Component {
    render() {
        return (
            <CardsList {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.app.listView,
        screenSize: state.app.screenSize
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(CardsListContainer)