import React from 'react';
import i18next from 'i18next';
import './RowCard.sass';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'



export default class RowCard extends React.Component {
    /**
     * Class RowCard gets few props like card and draws a card. Simple card.
     * 
     * @param {object} cards — object contains all card params like title.
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    onCallVideoModalHandler = (e) => {
        /** 
         * Call modal window if card is video-card.
         */
        e.preventDefault();
        this.props.setOpenVideoModal(true);
        this.props.setVideoModalData({...this.props.card});
    }

    onCallContentModalHandler = (e) => {
        /** 
         * Call modal window if card is source-card.
         */
        e.preventDefault();
        this.props.setOpenContentModal(true);
        this.props.setContentModalData({...this.props.card});
    }

    onAddCardToCollection = () =>  {
        this.props.setMyCardsCollection([...this.props.myCardsCollection, this.props.card.id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...this.props.myCardsCollection, this.props.card.id]));
    }

    onRemoveCardFromCollection = () =>  {
        let filteredCards = this.props.myCardsCollection.filter(card => card !== this.props.card.id);
        this.props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    addNotify = () => 
        toast("Zum Regal hinzugefügt", 
        {
            position: 'bottom-right',
            progressStyle: {background: "#004994"},
            style: {color: 'black'},
            role: 'alert'
        }
    )
    

    removeNotify = () => 
        toast("Aus dem Regal entfernt", 
        {
            position: 'bottom-right',
            progressStyle: {background: "#004994"},
            style: {color: 'black'},
            role: 'alert'
        }
    )

    render(){
        const { 
            id,
            media_cover, 
            portal_title, 
            is_recommendation,
            is_withdrawn,
            link,
            type } = this.props.card;
        return (
            <div
                className={ ` row-card ${ this.props.className } ${ type ? 'row-card--' + type.type : '' }` }
                title={ portal_title }>
                <div className="row-card__image-area">
                    <a 
                        href={ is_withdrawn === null || is_withdrawn === false ? link : 'undefined' }
                        target={ is_withdrawn === null || is_withdrawn === false ? '_blank' : 'undefined' }
                        onClick={ type ? type.type === 'video' ? this.onCallVideoModalHandler : type.type === 'bibliotheken' ? this.onCallContentModalHandler : () => false : () => false } rel="noreferrer">
                        { media_cover ? (
                            <img 
                                className="row-card__image"
                                data-id={ id }
                                src={ media_cover }
                                alt={ portal_title }/>
                        ) : (
                            <span>No image</span>
                        )}
                    </a>
                </div>
                <a 
                    className="row-card__info"
                    href={ is_withdrawn === null || is_withdrawn === false ? link : 'undefined' }
                    target={ is_withdrawn === null || is_withdrawn === false ? '_blank' : 'undefined' }
                    onClick={ type ? type.type === 'video' ? this.onCallVideoModalHandler : type.type === 'bibliotheken' ? this.onCallContentModalHandler : () => false : () => false } rel="noreferrer">
                    <span 
                        className="row-card__title">
                        { portal_title }
                    </span>
                    { (is_recommendation === 1 || type) &&
                        <div
                            className="row-card__subtitle">
                            { is_recommendation === 1 && 
                                <span
                                    className="row-card__type row-card__type--featured">
                                    { i18next.t('Featured') }
                                </span>
                            }
                            { type &&
                                <span 
                                    className="row-card__type">
                                    { type.title }
                                </span>
                            }
                        </div>
                    }
                </a>
                {
                    this.props.myCardsCollection.includes(id) ? 
                    <button 
                        className="row-card__button row-card__button--remove-from-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        onClick={ () => {this.onRemoveCardFromCollection(); this.removeNotify() }    }>
                        <span>
                        <svg
                            height="24"
                            width="24">
                                <title>{i18next.t('Remove from')}</title>
                            <use xlinkHref="svg/bootstrap-icons.svg#check" />
                        </svg>
                        </span>
                    </button> :
                    <button 
                        className="row-card__button row-card__button--add-to-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        onClick={ () => {this.onAddCardToCollection(); this.addNotify()} }>
                        <span>
                            <svg
                                height="16"
                                width="16">
                                    <title>{i18next.t('Add to')}</title>
                                <use xlinkHref="svg/bootstrap-icons.svg#plus-lg" />
                            </svg>
                        </span>
                    </button>
                }
            </div>
        )
    }
}

RowCard.defaultProps = {
    card: {},
    myCardsCollection: []
}

RowCard.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    setOpenContentModal: PropTypes.func,
    setOpenVideoModal: PropTypes.func,
    setVideoModalData: PropTypes.func,
    setContentModalData: PropTypes.func,
    card: PropTypes.object,
    myCardsCollection: PropTypes.array,
    setMyCardsCollection: PropTypes.func,
    className: PropTypes.string
}