import React from 'react';
import PropTypes from 'prop-types'


export const DropdownLinkButton = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className="desktop-view-button"
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }} >
        {children}
        <svg
            height="13"
            width="13">
                <title>Öffnen</title>
            <use
                xlinkHref="svg/bootstrap-icons.svg#chevron-down" />
        </svg>
    </button>
));

DropdownLinkButton.displayName = 'DropdownLinkButton';

DropdownLinkButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ])

}