import React from 'react';
import ShelfBox from './ShelfBox';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class ShelfBoxContainer extends React.Component {
    render() {
        return (
            <ShelfBox {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.app.listView,
        screenSize: state.app.screenSize,
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ShelfBoxContainer)