import React from 'react';
import ContentModal from './ContentModal';
import { connect } from 'react-redux';
import { 
    setOpenContentModal,
    setContentModalData
} from '../../../store/app/actions';
import { setMyCardsCollection } from '../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class ContentModalContainer extends React.Component {
    render() {
        return (
            <ContentModal {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showContentModal: state.app.showContentModal,
        contentModalData: state.app.contentModalData,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setOpenContentModal,
    setContentModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ContentModalContainer)