import React from 'react';
import Logo from './Logo';
import { connect } from 'react-redux';
import { 
    clearFilters
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class LogoContainer extends React.Component {
    render() {
        return (
            <Logo {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groupesList: state.app.groupesList,
        searchString: state.filter.searchString,
    }
}
  
const mapDispatchToProps = {
    clearFilters
}
  
export default connect(mapStateToProps, mapDispatchToProps)(LogoContainer)