import {
    SHARE_SET_MY_CARDS
} from "./actions";
  


const defaultState = {
    myCardsCollection: [],
}
  
export const shareReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SHARE_SET_MY_CARDS:
            return { ...state, myCardsCollection: action.payload };
    
        default: return state;
    }
}