export default function orderBy(cards, order) {
    return cards.sort((a, b) => {
        switch (order) {
            case 1:
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                else return -1;
            case 2:
                return new Date(b.released_at) - new Date(a.released_at);
            case 3:
                return b.is_recommendation - a.is_recommendation || a.sort - b.sort;
            default:
                return a.sort - b.sort;
        }
    })
}